@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

@media (prefers-color-scheme: dark) {
  /* :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  } */
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

/* Mobile View */
@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide the sidebar on small screens */
  }

  /* ... other mobile-specific styles ... */
}

/* Tablet and Desktop View */
@media (min-width: 769px) {
  /* ... styles for larger screens ... */
}

/* Tailwind utilities for responsiveness */
@media (max-width: 768px) {
  .toggle-button {
    display: block;
  }
}

@media (min-width: 769px) {
  .toggle-button {
    display: none;
  }
}
